import Vue from "vue"
import VueI18n from "vue-i18n"
import cn from "./lang/cn"
import en from "./lang/en"
import {getCache} from "@/utils/methods.js"
Vue.use(VueI18n)
const messages = { cn,en }
const i18n = new VueI18n({
  locale: getCache('lang') || 'cn',
  messages
})

export default i18n