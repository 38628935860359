export default {
  nav: {
    home: "Home",
    about: "About",
    product: "Product",
    solution: "Solution",
    customerService: "Customer Service",
    consultingTrends: "Consulting Trends",
    contactUs: "Contact us",
    
    enterpriseIntroduction: "Enterprise Introduction",
    honoraryQualifications: "Honorary Qualifications",
    qualityAssurance: "Quality Assurance",
    companyVideo: "Company Video",
    join: "Join",
    
    intelligentHardware: "intelligent hardware",
    softwarePlatform: "software platform",
  }
}