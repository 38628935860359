export function setCache(...g){
  g[1] = JSON.stringify(g[1])
  return window.localStorage.setItem(...g)
}

export function getCache(...g){
  return JSON.parse(window.localStorage.getItem(...g) || null)
}

export function rmCache(...g){
  return window.localStorage.removeItem(...g)
}

export function asyncSetTimeout(time, cb = ()=>{}){
  return new Promise(res => {
    const t = setTimeout(()=>{
      res(t)
      cb()
      clearTimeout(t)
    }, time)
  })
}

export function asyncSetInterval(time, cb = ()=>{}){
  return new Promise(res => {
    const t = setInterval(()=>{
      cb(t, res)
    }, time)
  })
}

// export function asyncMap(list, cb){
//   form
// }