import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n/i18n"

// 全局样式
import "./style/index.scss"

// 配置meta
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);

// 水波纹
import vWaves from "@/assets/waves"
import "@/assets/waves/waves.css"
Vue.use(vWaves)

// 引入iconfont
import "@/assets/icon/iconfont.css"

// 引入轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

// 全局混入
// import mixinIndex from '@/mixins/index'
// Vue.mixin(mixinIndex)

Vue.use(VueAwesomeSwiper)

// 配置百度地图
import baiduMap from "vue-baidu-map"
Vue.use(baiduMap, {
  ak: 'MWt2G0hZ5L9zu2gYGxvGsyLuzTck6HNL'
})


// 全局组件
Vue.component("page-header", () => import("./components/pageHeader"))
Vue.component("page-nav", () => import("./components/pageNav"))
Vue.component("page-footer", () => import("./components/pageFooter"))
Vue.component("page-fixed", () => import("./components/pageFixed"))
Vue.component("message-box", () => import("./components/messageBox"))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
