import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/about/video',
    name: 'Video',
    component: () => import('../views/about/video.vue')
  },
  {
    path: '/about/:type',
    name: 'About',
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/product/index.vue')
  },
  {
    path: '/product/detail',
    name: 'Product',
    component: () => import('../views/product/detail.vue')
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('../views/solution/index.vue')
  },
  {
    path: '/customerService/afterSaleService',
    name: 'CustomerService',
    component: () => import('../views/customerService/afterSaleService.vue')
  },
  {
    path: '/customerService/download',
    name: 'CustomerService',
    component: () => import('../views/customerService/download.vue')
  },
  {
    path: '/customerService/commonProblem',
    name: 'CustomerService',
    component: () => import('../views/customerService/commonProblem.vue')
  },
  // 资讯动态
  {
    path: '/consultingTrends/detail',
    name: 'ConsultingTrends',
    component: () => import('../views/consultingTrends/detail.vue')
  },
  {
    path: '/consultingTrends/:type',
    name: 'ConsultingTrends',
    component: () => import('../views/consultingTrends/index.vue')
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/contactUs/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
