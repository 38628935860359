export default {
  nav: {
    home: "首页",
    about: "关于多知",
    product: "产品中心",
    solution: "解决方案",
    customerService: "客户服务",
    consultingTrends: "资讯动态",
    contactUs: "联系我们",

    enterpriseIntroduction: "企业介绍",
    honoraryQualifications: "荣誉资质",
    qualityAssurance: "品质保障",
    companyVideo: "多知视频",
    join: "加入多知",

    intelligentHardware: "智能硬件",
    softwarePlatform: "软件平台",

    afterSaleService: "售后服务",
    download: "下载中心",
    commonProblem: "常见问题",
    companyDynamics: "公司动态",
    industryDynamics: "行业动态",
  }
}